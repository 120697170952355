import { Injectable, inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  Route,
  CanActivateFn
} from '@angular/router';
import { AuthenticationService } from './authentication.service';
import { Role, AdministrationRole } from 'api/enums';
import { TryActivateResult } from './try-activate-result.enum';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService
  ) {}

  canActivate(
    activatedRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const activationResult = this.tryActivate(activatedRoute.routeConfig);

    if (activationResult === TryActivateResult.NotLoggedIn) {
      let returnTo = activatedRoute.url.toString();
      if (returnTo === '') returnTo = state.url.toString();

      this.router.navigate(['/login'], { queryParams: { returnTo: returnTo } });
      return false;
    } else if (activationResult === TryActivateResult.NoAccess) {
      this.router.navigate(['/no-access']);
      return false;
    }

    return true;
  }

  tryActivate(route: Route): TryActivateResult {
    if (!this.authenticationService.isAuthenticated()) {
      return TryActivateResult.NotLoggedIn;
    }

    if (route.data && (route.data.userRoles || route.data.adminRoles)) {
      let roles = (route.data.userRoles || 0) as Role;
      let adminRoles = (route.data.adminRoles || 0) as AdministrationRole;

      if (!this.authenticationService.hasAnyRole(roles, adminRoles)) {
        return TryActivateResult.NoAccess;
      }
    }

    return TryActivateResult.Success;
  }
}

export const AuthGuard: CanActivateFn = (
  next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): boolean => {
  return inject(AuthGuardService).canActivate(next, state);
};
